<script setup>
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();

const moderatorSettings = computed(
  () => store.state.meeting.roomInfo.moderator
);

const updateSettings = (action) => {
  store.dispatch("meeting/updateRoomSettings", action);
};
</script>

<template>
  <div class="settings-rules">
    <h4>Права участников</h4>
    <p>
      Выберите действия, которые будут доступны участникам после подключения к
      онлайн-встрече
    </p>

    <div class="settings-rules__list">
      <button
        type="button"
        :class="{ active: !moderatorSettings.audio_cant_unmute }"
        @click="updateSettings('audio_cant_unmute')"
      >
        <i class="icon icon-micro-on" />
        Возможность включения микрофона
        <span :class="{ active: !moderatorSettings.audio_cant_unmute }"></span>
      </button>
      <button
        type="button"
        :class="{ active: !moderatorSettings.video_cant_unhide }"
        @click="updateSettings('video_cant_unhide')"
      >
        <i class="icon icon-camera-on" />
        Возможность включения камеры
        <span :class="{ active: !moderatorSettings.video_cant_unhide }"></span>
      </button>
      <button
        type="button"
        :class="{ active: !moderatorSettings.screen_cant_share }"
        @click="updateSettings('screen_cant_share')"
      >
        <i class="icon icon-screenshare-on" />
        Возможность демонстрации экрана
        <span :class="{ active: !moderatorSettings.screen_cant_share }"></span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.settings-rules {
  width: 100%;
  height: 100%;

  @media (max-width: 1023px) {
    background: $background-light;
    padding: calc($base-space * 5) calc($base-space * 4);
    border-radius: 16px;
  }

  > h4 {
    @include h4();
    color: $text-dark-primary;
  }

  > p {
    margin: calc($base-space * 2) 0 0;
    @include s-text-regular();
    color: $text-dark-third;
  }

  &__list {
    margin: calc($base-space * 6) 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: calc($base-space * 3);
    width: 100%;

    > button {
      width: 100%;
      padding: calc($base-space * 4);
      display: grid;
      grid-template-columns: 24px 1fr auto;
      gap: calc($base-space * 3);
      @include s-text-regular();
      color: $text-dark-primary;
      border-radius: 16px;
      background: $background-ultralight-gray;
      text-align: left;
      transition: ease-out 0.05s;

      > i {
        width: 24px;
        height: 24px;
        background-color: $text-dark-third;
      }

      > span {
        width: 40px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid $stroke-second;
        position: relative;
        transition: ease-out 0.05s;

        &:after {
          content: "";
          width: 16px;
          height: 16px;
          background-color: $stroke-second;
          position: absolute;
          border-radius: 50%;
          left: 4px;
          top: 3px;
          transition: ease-out 0.05s;
        }
      }

      &:hover {
        background-color: $background-light-gray;

        > span {
          border-color: $stroke-primary;

          &:after {
            background-color: $stroke-primary;
          }
        }
      }

      &.active {
        background-color: $background-ultralight-accent;

        > span {
          border-color: $background-main-accent;
          background-color: $background-main-accent;

          &:after {
            background-color: $background-light;
            right: 4px;
            left: auto;
          }
        }

        &:hover {
          background-color: $background-light-accent;
        }
      }
    }
  }
}
</style>
