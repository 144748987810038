<script>
import { mapState } from "vuex";
import { getLessonTypeInfo } from "@/package/helpers/courseStatuses";
import LessonTestTemplate from "@/components/Lesson/Templates/LessonTestTemplate.vue";
import LessonQuestionTemplate from "@/components/Lesson/LessonQuestionTemplate.vue";
import { dateTimeFormat } from "@/package/helpers/timeFormats";

export default {
  name: "LessonPagePreview",
  components: { LessonQuestionTemplate, LessonTestTemplate },

  computed: {
    ...mapState({
      lesson: (state) => state.lesson.lesson,
    }),

    formattedDescription() {
      return this.lesson.description.replace(/\n/g, "<br />");
    },
  },

  methods: {
    getLessonTypeInfo,
    dateTimeFormat,

    footnoteIcon(style) {
      switch (style) {
        case "info":
          return "info";

        case "warning":
          return "warning";

        case "error":
          return "close-circle";

        case "question":
          return "faq";

        default:
          return "pin";
      }
    },
  },
};
</script>

<template>
  <div class="lesson-preview">
    <div class="lesson-preview__header">
      <h3>Режим предпросмотра</h3>

      <button
        class="button button_secondary button_medium"
        @click="$emit('close')"
      >
        Вернуться к редактированию
      </button>
    </div>

    <div class="lesson-preview__content">
      <header class="lesson-header" v-if="lesson">
        <div class="container">
          <section class="lesson-header__info">
            <p
              class="label"
              :class="`label_lesson-${getLessonTypeInfo(lesson.type).id}`"
            >
              <i
                class="icon"
                :class="`icon-${getLessonTypeInfo(lesson.type).icon}`"
              />
              {{ getLessonTypeInfo(lesson.type).name }}
            </p>

            <h1>{{ lesson.name }}</h1>

            <h3 v-if="lesson.description" v-html="formattedDescription" />
          </section>

          <section
            class="lesson-online"
            v-if="
              (lesson.type === 'online' || lesson.type === 'webinar') &&
              lesson.data
            "
          >
            <div class="lesson-online__card" v-if="lesson.data.date">
              <i class="icon icon-calendar" />

              <p>
                Дата и время начала
                <span>{{ dateTimeFormat(lesson.data.date) }}</span>
              </p>
            </div>

            <div class="lesson-online__card" v-if="lesson.data.time">
              <i class="icon icon-clock" />

              <p>
                Длительность встречи
                <span>{{ lesson.data.time.name }}</span>
              </p>
            </div>
            <template v-if="lesson.data.status">
              <p v-if="lesson.data.status === 'created'">
                Ссылка будет доступна после запуска
                {{ lesson.type === "webinar" ? "вебинара" : "встречи" }}
              </p>

              <a
                :href="lesson.data.link"
                v-if="lesson.data.link && lesson.data.status === 'started'"
                target="_blank"
              >
                {{
                  lesson.type === "webinar"
                    ? "Перейти к вебинару"
                    : "Перейти к онлайн-встрече"
                }}
                <i class="icon icon-short-arrow-right" />
              </a>

              <router-link
                :to="{
                  name: lesson.type === 'online' ? 'MeetView' : 'WebinarView',
                  params: { id: lesson.data.webinar_room },
                }"
                v-if="
                  lesson.data.webinar_room && lesson.data.status === 'started'
                "
                target="_blank"
              >
                {{
                  lesson.type === "webinar"
                    ? "Перейти к вебинару"
                    : "Перейти к онлайн-встрече"
                }}
                <i class="icon icon-short-arrow-right" />
              </router-link>
            </template>

            <a
              :href="lesson.data.link"
              v-if="lesson.data.link && !lesson.data.status"
              target="_blank"
            >
              {{
                lesson.type === "webinar"
                  ? "Перейти к вебинару"
                  : "Перейти к онлайн-встрече"
              }}
              <i class="icon icon-short-arrow-right" />
            </a>
          </section>
        </div>
      </header>

      <template v-if="lesson.type !== 'online' && lesson.type !== 'webinar'">
        <main class="lesson-content">
          <div class="container">
            <template v-for="(template, index) in lesson.data" :key="index">
              <h1 class="template-h1" v-if="template.type === 'h1'">
                {{ template.value }}
              </h1>

              <h2 class="template-h2" v-if="template.type === 'h2'">
                {{ template.value }}
              </h2>

              <h3 class="template-h3" v-if="template.type === 'h3'">
                {{ template.value }}
              </h3>

              <a
                class="template-link"
                v-if="template.type === 'link'"
                :href="template.value"
                target="_blank"
              >
                {{ template.value }}
              </a>

              <p
                class="template-text"
                v-if="template.type === 'text'"
                v-html="template.value"
              />

              <ul
                class="template-bullet-list"
                v-if="template.type === 'bullet-list'"
              >
                <li v-for="(item, index) in template.value" :key="index">
                  <i class="icon icon-bullet-filled" />

                  <p v-html="item" />
                </li>
              </ul>

              <ol
                class="template-numeric-list"
                v-if="template.type === 'numeric-list'"
              >
                <li v-for="(item, index) in template.value" :key="index">
                  <span>{{ index + 1 }}.</span>

                  <p v-html="item" />
                </li>
              </ol>

              <div
                class="template-footnote"
                :class="`template-footnote_${template.style}`"
                v-if="template.type === 'footnote'"
              >
                <span>
                  <i
                    class="icon"
                    :class="`icon-${footnoteIcon(template.style)}`"
                  />
                </span>

                <p v-html="template.value" />
              </div>

              <blockquote
                class="template-quote"
                v-if="template.type === 'quote'"
                v-html="template.value"
              />

              <vue-latex
                :expression="template.value"
                display-mode
                class="template-formula"
                v-if="template.type === 'formula'"
              />

              <code class="template-code" v-if="template.type === 'code'">
                {{ template.value }}
              </code>

              <div class="template-images" v-if="template.type === 'image'">
                <div
                  class="template-image"
                  v-for="(image, index) in template.value"
                  :key="index"
                >
                  <div
                    class="template-image__preview"
                    :style="{ backgroundImage: `url('${image.url}')` }"
                  />

                  <p v-if="image.name">{{ image.name }}</p>
                </div>
              </div>

              <div class="template-movie" v-if="template.type === 'movie'">
                <iframe
                  v-if="template?.value?.service === 'youtube'"
                  width="100%"
                  height="400"
                  :src="`https://www.youtube.com/embed/${template.value.id};controls=0`"
                />

                <iframe
                  v-if="template?.value?.service === 'vk'"
                  :src="`https://vk.com/video_ext.php?oid=-${template.value.oid}&id=${template.value.id}`"
                  width="100%"
                  height="400"
                  allowfullscreen
                />

                <iframe
                  v-if="template?.value?.service === 'rutube'"
                  :src="`https://rutube.ru/play/embed/${template.value.id}`"
                  width="100%"
                  height="400"
                  allowFullScreen
                />
              </div>

              <div class="template-file" v-if="template.type === 'file'">
                <i class="icon icon-file" />

                <p>
                  {{ template.value.name }}
                </p>

                <div class="template-file__actions">
                  <a
                    target="_blank"
                    :href="template.value.url"
                    class="button button_secondary button_small"
                  >
                    Открыть
                  </a>

                  <a
                    :href="template.value.url"
                    download
                    target="_blank"
                    class="button button_secondary button_small"
                  >
                    Скачать
                  </a>
                </div>
              </div>

              <div
                class="template-divider"
                v-if="template.type === 'divider'"
              />

              <LessonTestTemplate
                v-if="template.type === 'test'"
                :test="template.value"
                :index="index"
              />

              <LessonQuestionTemplate
                v-if="template.type === 'question'"
                :question="template.value"
                :index="index"
              />
            </template>
          </div>
        </main>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.lesson-preview {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: $background-light;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ($base-space * 4);
    background-color: $background-light;

    @media (max-width: 1023px) {
      padding: ($base-space * 3) ($base-space * 8);
    }

    @media (max-width: 767px) {
      padding: ($base-space * 3) ($base-space * 6);
    }

    @media (max-width: 424px) {
      padding: ($base-space * 3) ($base-space * 5);
    }

    @media (max-width: 374px) {
      padding: ($base-space * 3) ($base-space * 4);
    }

    > h3 {
      @include m-text-regular();
      color: $text-dark-primary;

      @media (max-width: 460px) {
        display: none;
      }
    }
  }

  &__content {
    height: calc(100vh - 80px);
    width: 100%;
    background-color: $background-light;
    overflow: auto;

    @media (max-width: 1023px) {
      height: calc(100vh - 64px);
    }
  }
}

.lesson-header {
  width: 100%;
  background-color: $background-ultralight-accent;
  padding: ($base-space * 8) 0 ($base-space * 24);

  @media (max-width: 1023px) {
    padding: ($base-space * 6) 0 ($base-space * 15);
  }

  &__info {
    max-width: 900px;
    margin: ($base-space * 24) auto 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1023px) {
      margin: ($base-space * 12) 0 0;
    }

    > h1 {
      @include h2();
      margin: ($base-space * 8) 0 0;
      color: $text-dark-primary;
      word-break: break-word;

      @media (max-width: 1023px) {
        margin: ($base-space * 6) 0 0;
      }
    }

    > h3 {
      color: $text-dark-primary;
      @include l-text-regular();
      margin: ($base-space * 8) 0 0;

      @media (max-width: 1023px) {
        margin: ($base-space * 6) 0 0;
      }
    }
  }
}

.lesson-online {
  max-width: 900px;
  margin: ($base-space * 15) auto 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "date time" "link link";
  gap: $base-space * 4;

  @media (max-width: 1023px) {
    margin: ($base-space * 8) auto 0;
    gap: $base-space * 3;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas: "date" "time" "link";
    gap: $base-space * 2;
  }

  &__card {
    padding: ($base-space * 6);
    background-color: $background-light;
    border-radius: 16px;
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    gap: $base-space * 4;

    @media (max-width: 767px) {
      padding: ($base-space * 4);
      grid-template-columns: 28px 1fr;
    }

    > i {
      width: 32px;
      height: 32px;
      background-color: $text-accent;

      @media (max-width: 767px) {
        width: 28px;
        height: 28px;
      }
    }

    > p {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: $base-space / 2;
      @include s-text-regular();
      color: $text-dark-third;

      > span {
        @include xl-text-regular();
        color: $text-dark-primary;
      }
    }
  }

  > a {
    grid-area: link;
    padding: ($base-space * 6) ($base-space * 8);
    border-radius: 16px;
    background-color: $background-light;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $base-space * 2;
    @include l-text-regular();
    color: $text-accent;

    @media (max-width: 767px) {
      padding: ($base-space * 4);
    }

    &:hover,
    &:active {
      color: $text-accent-dark;

      > i {
        background-color: $text-accent-dark;
      }
    }

    > i {
      background-color: $text-accent;
      width: 28px;
      height: 28px;

      @media (max-width: 1023px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  > p {
    grid-area: link;
    padding: ($base-space * 6) ($base-space * 8);
    border-radius: 16px;
    background-color: $background-light;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $base-space * 2;
    @include l-text-regular();
    color: $text-dark-third;
    text-align: center;

    @media (max-width: 767px) {
      padding: ($base-space * 4);
    }
  }
}

.lesson-content {
  padding: ($base-space * 24) 0;

  @media (max-width: 1023px) {
    padding: ($base-space * 15) 0;
  }

  > .container {
    display: grid;
    grid-template-columns: 1fr;
    max-width: 900px;

    @media (max-width: 1023px) {
      max-width: none;
    }
  }
}

.template-h1 {
  margin: ($base-space * 16) 0 ($base-space * 6);
  color: $text-dark-primary;
  @include h3();
  word-break: break-word;

  @media (max-width: 1023px) {
    margin: ($base-space * 8) 0 ($base-space * 4);
  }

  &:first-child {
    margin: 0 0 ($base-space * 6);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 4);
    }
  }
}

.template-h2 {
  margin: ($base-space * 12) 0 ($base-space * 4);
  color: $text-dark-primary;
  @include h4();
  word-break: break-word;

  @media (max-width: 1023px) {
    margin: ($base-space * 6) 0 ($base-space * 3);
  }

  &:first-child {
    margin: 0 0 ($base-space * 4);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 3);
    }
  }
}

.template-h3 {
  margin: ($base-space * 8) 0 ($base-space * 2);
  color: $text-dark-primary;
  @include h5();
  word-break: break-word;

  @media (max-width: 1023px) {
    margin: ($base-space * 4) 0 ($base-space * 2);
  }

  &:first-child {
    margin: 0 0 ($base-space * 2);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 2);
    }
  }
}

.template-text {
  margin: ($base-space * 2) 0;
  color: $text-dark-primary;
  @include l-text-regular();
  word-break: break-word;

  &:first-child {
    margin: 0 0 ($base-space * 2);
  }
}

.template-bullet-list {
  margin: ($base-space * 2) 0;

  &:first-child {
    margin: 0 0 ($base-space * 2);
  }

  > li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: $base-space * 2;

    > i {
      width: 20px;
      height: 20px;
      background-color: $text-dark-primary;
    }

    > p {
      color: $text-dark-primary;
      @include l-text-regular();
      word-break: break-word;
    }
  }
}

.template-numeric-list {
  margin: ($base-space * 2) 0;

  &:first-child {
    margin: 0 0 ($base-space * 2);
  }

  > li {
    color: $text-dark-primary;
    @include l-text-regular();
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: $base-space * 2;

    > p {
      word-break: break-word;
    }
  }
}

.template-footnote {
  margin: ($base-space * 8) (-$base-space * 10);
  padding: ($base-space * 10);
  border-radius: 16px;
  background-color: $background-light-gray;
  border: 1px solid $background-light-gray;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: start;
  gap: $base-space * 6;

  @media (max-width: 1023px) {
    margin: ($base-space * 3) 0;
    padding: ($base-space * 4);
    grid-template-columns: 32px 1fr;
    gap: $base-space * 4;
  }

  &:first-child {
    margin: 0 (-$base-space * 10) ($base-space * 8);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 3);
    }
  }

  > p {
    @include l-text-regular();
    color: $text-dark-primary;
    width: 100%;
    outline: none;
    padding: ($base-space * 2) 0 0;
    word-break: break-word;

    @media (max-width: 1023px) {
      padding: ($base-space) 0 0;
    }
  }

  > span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(4, 3, 24, 1);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
    }

    > i {
      width: 24px;
      height: 24px;
      background-color: $background-light;

      @media (max-width: 1023px) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &_info {
    background-color: #dcebff;
    border-color: rgba(9, 20, 51, 0.1);

    > span {
      background-color: #197bff;
    }
  }

  &_warning {
    background-color: #fff3cf;
    border-color: rgba(9, 20, 51, 0.1);

    > span {
      background-color: #ebb000;
    }
  }

  &_error {
    background-color: #ffe3e4;
    border-color: rgba(9, 20, 51, 0.1);

    > span {
      background-color: #f24c57;
    }
  }

  &_question {
    background-color: #f5e8ff;
    border-color: rgba(9, 20, 51, 0.1);

    > span {
      background-color: #b24dff;
    }
  }
}

.template-quote {
  margin: ($base-space * 8) 0;
  padding: ($base-space * 6) ($base-space * 8);
  border-left: 2px solid $stroke-primary;
  background-color: $background-ultralight-gray;
  @include l-text-regular();
  color: $text-dark-primary;

  @media (max-width: 1023px) {
    margin: ($base-space * 3) 0;
    padding: ($base-space * 4) ($base-space * 6);
  }

  &:first-child {
    margin: 0 0 ($base-space * 8);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 3);
    }
  }
}

.template-images {
  margin: ($base-space * 8) (-$base-space * 10);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: $base-space * 4;

  @media (max-width: 1023px) {
    margin: ($base-space * 3) 0;
    flex-flow: column;
    grid-gap: $base-space * 3;
  }

  &:first-child {
    margin: 0 (-$base-space * 10) ($base-space * 8);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 3);
    }
  }
}

.template-image {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $base-space * 4;
  width: 100%;

  @media (max-width: 1023px) {
    grid-gap: $base-space * 2;
  }

  &__preview {
    height: 400px;
    border-radius: 16px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1023px) {
      height: 160px;
    }
  }

  > p {
    @include m-text-regular();
    color: $text-dark-second;
    text-align: center;
    word-break: break-word;
  }
}

.template-movie {
  margin: ($base-space * 8) 0;

  @media (max-width: 1023px) {
    margin: ($base-space * 3) 0;
  }

  &:first-child {
    margin: 0 0 ($base-space * 8);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 3);
    }
  }

  > iframe {
    border: none;

    @media (max-width: 1023px) {
      height: 280px;
    }
  }

  @media (max-width: 1023px) {
    margin: ($base-space * 3) 0;
  }
}

.template-file {
  margin: ($base-space * 2) 0;
  padding: ($base-space * 5);
  border-radius: 16px;
  background-color: $background-ultralight-gray;
  display: grid;
  grid-template-columns: 40px 1fr auto;
  grid-template-areas: "icon name actions";
  grid-gap: $base-space * 4;
  align-items: center;

  @media (max-width: 1023px) {
    grid-template-areas: "icon name" "actions actions";
    grid-template-columns: 32px 1fr;
    grid-gap: ($base-space * 4) ($base-space * 2);
    padding: ($base-space * 4);
  }

  &:first-child {
    margin: 0 0 ($base-space * 2);
  }

  > i {
    width: 40px;
    height: 40px;
    background-color: $stroke-accent;
    grid-area: icon;

    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
    }
  }

  > p {
    @include m-text-regular();
    color: $text-dark-primary;
    grid-area: name;
    word-break: break-word;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $base-space * 2;
    grid-area: actions;

    @media (max-width: 1023px) {
      width: 100%;
      justify-content: space-between;

      > a {
        width: 100%;
      }
    }
  }
}

.template-divider {
  margin: ($base-space * 12) 0;
  width: 100%;
  height: 1px;
  background-color: $stroke-third;

  @media (max-width: 1023px) {
    margin: ($base-space * 6) 0;
  }

  &:first-child {
    margin: 0 0 ($base-space * 12);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 6);
    }
  }
}

.template-link {
  margin: ($base-space * 2) 0;
  color: $text-accent;
  @include m-text-regular();
  word-break: break-word;

  &:first-child {
    margin: 0 0 ($base-space * 2);
  }
}

.template-code {
  margin: calc($base-space * 2) 0 0;
  padding: calc($base-space * 4);
  white-space: pre-wrap;
  border-radius: 16px;
  background-color: $background-ultralight-accent;
  @include l-text-regular();
}

.template-formula {
  margin: calc($base-space * 2) 0 0;
}
</style>
