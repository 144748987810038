<script setup></script>

<template>
  <div class="meeting-loading">
    <div class="meeting-loading__wrapper">
      <img src="@/assets/images/loader.svg" alt="" />
      <p>Присоединение к встрече</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.meeting-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100wv;
  height: 100vh;
  background: $background-ultralight-gray;

  @media (max-width: 767px) {
    background: $background-light;
  }

  &__wrapper {
    padding: calc($base-space * 12);
    border-radius: 24px;
    background: $background-light;
    box-shadow: 0 4px 32px 0 rgba(7, 6, 52, 0.03);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: calc($base-space * 6);

    @media (max-width: 1023px) {
      padding: calc($base-space * 8);
    }

    @media (max-width: 767px) {
      box-shadow: none;
    }

    > img {
      width: 48px;
    }

    > p {
      @include m-text-medium();
      color: $text-accent;
      text-align: center;
    }
  }
}
</style>
