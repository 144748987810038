<script setup>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import moment from "moment/moment";

const store = useStore();
const meeting = computed(() => store.state.meeting.meeting);

const timer = ref({
  minutes: 0,
  hours: 0,
});

onMounted(() => {
  let endDate = moment(meeting.value.ended_at, "YYYY-MM-DD HH:mm:ss");
  let startDate = moment(meeting.value.started_at, "YYYY-MM-DD HH:mm:ss");
  let duration = moment.duration(endDate.diff(startDate));
  timer.value.hours = duration.hours();
  timer.value.minutes = duration.minutes();
});
</script>

<template>
  <section class="meeting-end">
    <div class="meeting-end__wrapper">
      <span>
        <i class="icon icon-live_fill" />
      </span>
      <h3>Встреча завершена</h3>

      <p>
        Длительность встречи
        <template v-if="timer.hours">{{ timer.hours }} ч</template>
        {{ timer.minutes }} мин
      </p>

      <router-link
        :to="{ name: 'CourseView', params: { id: meeting.course_id } }"
        class="button button_large"
      >
        Вернуться к курсу
      </router-link>
    </div>
  </section>
</template>

<style scoped lang="scss">
.meeting-end {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background-color: $background-ultralight-gray;

  @media (max-width: 767px) {
    background: $background-light;
  }

  &__wrapper {
    width: 480px;
    padding: calc($base-space * 12);
    border-radius: 24px;
    background-color: $background-light;
    box-shadow: 0 4px 32px 0 rgba(7, 6, 52, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    @media (max-width: 1023px) {
      padding: calc($base-space * 8);
    }

    @media (max-width: 767px) {
      box-shadow: none;
      padding: 16px;
      width: 100vw;
    }

    > span {
      width: 72px;
      height: 72px;
      background-color: #f34444;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      > i {
        width: 45px;
        height: 46px;
        background-color: $background-light;
      }
    }

    > h3 {
      @include h4();
      color: $text-dark-primary;
      text-align: center;
      margin: calc($base-space * 8) 0 0;

      @media (max-width: 1023px) {
        margin: calc($base-space * 6) 0 0;
      }
    }

    > p {
      margin: calc($base-space * 2) 0 0;
      color: $text-dark-second;
      @include m-text-regular();
      text-align: center;
    }

    > a {
      margin: calc($base-space * 8) 0 0;
      width: 100%;

      @media (max-width: 1023px) {
        margin: calc($base-space * 6) 0 0;
      }
    }
  }
}
</style>
