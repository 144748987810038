<script setup>
import { ref, defineEmits } from "vue";
import MeetingSettingsDevices from "@/components/Meeting/MeetingSettingsDevices.vue";
import MeetingSettingRules from "@/components/Meeting/MeetingSettingRules.vue";

const selectTab = ref("devices");
const emit = defineEmits(["close"]);
</script>

<template>
  <div class="settings-modal">
    <div class="settings-modal__wrapper">
      <button type="button" @click="emit('close')">
        <i class="icon icon-close" />
      </button>
      <div class="settings-modal__menu">
        <h5>Настройки встречи</h5>
        <nav>
          <button
            type="button"
            @click="selectTab = 'devices'"
            :class="{ active: selectTab === 'devices' }"
          >
            <i class="icon icon-micro-on" />
            Мои устройства
          </button>
          <button
            type="button"
            @click="selectTab = 'rules'"
            :class="{ active: selectTab === 'rules' }"
          >
            <i class="icon icon-people-fill" /> Права участников
          </button>
        </nav>
      </div>

      <div class="settings-modal__content">
        <MeetingSettingsDevices v-if="selectTab === 'devices'" />
        <MeetingSettingRules v-if="selectTab === 'rules'" />

        <button class="button button_medium" @click="emit('close')">
          Готово
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.settings-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: $background-main-overlay;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    position: relative;
    max-height: 92vh;
    overflow-y: auto;
    overflow-x: hidden;
    overscroll-behavior: contain;
    display: grid;
    grid-template-columns: 280px 520px;
    border-radius: 24px;
    height: 550px;

    > button {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 16px;
      right: 16px;

      > i {
        width: 32px;
        height: 32px;
        background: $text-dark-third;
      }
    }

    @media (max-width: 1023px) {
      padding: ($base-space * 12);
      width: calc(100vw - 64px);
      border-radius: 16px;
    }

    @media (max-width: 767px) {
      padding: ($base-space * 6);
      width: calc(100vw - 40px);
    }

    @media (max-width: 374px) {
      width: calc(100vw - 32px);
    }

    &_medium {
      padding: ($base-space * 12);

      @media (max-width: 1023px) {
        padding: ($base-space * 8);
      }

      @media (max-width: 767px) {
        padding: ($base-space * 6);
      }

      @media (max-width: 424px) {
        padding: ($base-space * 5);
      }

      @media (max-width: 374px) {
        padding: ($base-space * 4);
      }
    }

    &_fixed {
      overflow: visible;
    }

    > button {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 16px;
      right: 16px;

      > i {
        width: 32px;
        height: 32px;
        background: $text-dark-third;
      }
    }
  }

  &__menu {
    padding: calc($base-space * 8) calc($base-space * 4);
    border-radius: 24px 0 0 24px;
    background: $background-ultralight-gray;

    > h5 {
      color: $text-dark-third;
      @include m-text-medium();
    }

    > nav {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: calc($base-space * 5) 0 0;

      > button {
        width: 100%;
        padding: calc($base-space * 3) calc($base-space * 4);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: calc($base-space * 2);
        @include m-text-regular();
        color: $text-dark-third;
        border-radius: 12px;
        transition: ease-out 0.05s;

        > i {
          width: 20px;
          height: 20px;
          background-color: $text-dark-third;
          transition: ease-out 0.05s;
        }

        &:hover {
          color: $text-dark-primary;

          > i {
            background-color: $text-dark-primary;
          }
        }

        &.active {
          background-color: $background-light;
          color: $text-dark-primary;

          > i {
            background-clip: $text-dark-primary;
          }
        }
      }
    }
  }

  &__content {
    padding: calc($base-space * 8);
    background-color: $background-light;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}
</style>
