<script setup>
import AppSelector from "@/components/Ui/AppSelector.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import MeetingDeviceDisabled from "@/components/Meeting/MeetingDeviceDisabled.vue";
import { useWindowSize } from "@vueuse/core";

const route = useRoute();
const store = useStore();
const blockDevice = ref("");
const cameraVideo = ref(null);
const meeting = computed(() => store.state.meeting.meeting);
const { width } = useWindowSize();

const roomSettings = ref({
  audio: false,
  video: false,
  screenShare: false,
});

const isCameraAllowed = computed(() => store.state.meeting.isCameraAllowed);
const isEnumerateCameraDevices = computed(
  () => store.state.meeting.isEnumerateCameraDevices
);
const isAudioAllowed = computed(() => store.state.meeting.isAudioAllowed);
const isEnumerateAudioDevices = computed(
  () => store.state.meeting.isEnumerateAudioDevices
);

const audioOutputDevices = computed(
  () => store.state.meeting.audioOutputDevices
);

const cameraDevices = computed(() => store.state.meeting.cameraDevices);
const audioDevices = computed(() => store.state.meeting.audioDevices);

const selectedCameraDevice = computed(
  () => store.state.meeting.selectedCameraDevice
);
const selectedAudioDevice = computed(
  () => store.state.meeting.selectedAudioDevice
);

const selectedAudioOutputDevice = computed(
  () => store.state.meeting.selectedAudioOutputDevice
);

const selectedCamera = computed(() =>
  cameraDevices.value.find(
    (device) => device.deviceId === selectedCameraDevice.value
  )
);

const selectedMicro = computed(() =>
  audioDevices.value.find(
    (device) => device.deviceId === selectedAudioDevice.value
  )
);

const selectedOutputDevice = computed(() =>
  audioOutputDevices.value.find(
    (device) => device.deviceId === selectedAudioOutputDevice.value
  )
);

const cameraStream = computed(() => store.state.meeting.cameraStream);

const changeMicroStatus = () => {
  if (!isEnumerateAudioDevices.value) {
    blockDevice.value = "micro";
    return;
  }

  store.dispatch("meeting/handleAudio");
};

const changeCameraStatus = () => {
  if (!isEnumerateCameraDevices.value) {
    blockDevice.value = "camera";
    return;
  }

  store.dispatch("meeting/handleVideo");
};

const joinRoom = () => {
  store.dispatch("meeting/loadMeetingToken", route.params.id).then(() => {
    store.dispatch("meeting/joinRoom", roomSettings.value);
  });
};

const selectMicro = (item) => {
  store.commit("meeting/SET_STATE", {
    state: "selectedAudioDevice",
    value: item.deviceId,
  });
};

const selectOutputDevice = (item) => {
  store.commit("meeting/SET_STATE", {
    state: "selectedAudioOutputDevice",
    value: item.deviceId,
  });
};

const selectCamera = (item) => {
  store.commit("meeting/SET_STATE", {
    state: "selectedCameraDevice",
    value: item.deviceId,
  });
  store.dispatch("meeting/changeCamera", item.deviceId);
};

watch(cameraStream, (newValue) => {
  nextTick(() => (cameraVideo.value.srcObject = newValue));
});

onMounted(() => {
  if (isCameraAllowed.value) {
    store.dispatch("meeting/changeCamera");
  }
});
</script>

<template>
  <MeetingDeviceDisabled
    v-if="blockDevice"
    :device="blockDevice"
    @close="blockDevice = ''"
  />

  <main class="meeting-settings">
    <div class="meeting-settings__wrapper">
      <div class="meeting-settings__info">
        <div class="meeting-name">
          <img src="@/assets/images/logo-meet.svg" alt="" />

          <h4 v-if="meeting.courseUnit">{{ meeting.courseUnit.name }}</h4>
        </div>

        <p>
          Выберите действия, которые будут доступны участникам после подключения
          к онлайн-встрече
        </p>

        <div class="meeting-settings__room">
          <button
            type="button"
            :class="{ active: roomSettings.audio }"
            @click="roomSettings.audio = !roomSettings.audio"
          >
            <i class="icon icon-micro-on" />
            Возможность включения микрофона
            <span :class="{ active: roomSettings.audio }"></span>
          </button>
          <button
            type="button"
            :class="{ active: roomSettings.video }"
            @click="roomSettings.video = !roomSettings.video"
          >
            <i class="icon icon-camera-on" />
            Возможность включения камеры
            <span :class="{ active: roomSettings.video }"></span>
          </button>
          <button
            type="button"
            :class="{ active: roomSettings.screenShare }"
            @click="roomSettings.screenShare = !roomSettings.screenShare"
          >
            <i class="icon icon-screenshare-on" />
            Возможность демонстрации экрана
            <span :class="{ active: roomSettings.screenShare }"></span>
          </button>
        </div>

        <button
          @click="joinRoom"
          class="button button_large"
          v-if="width > 1023"
        >
          Присоединиться к встрече
        </button>
      </div>
      <span></span>
      <div class="meeting-settings__devices">
        <div class="meeting-camera">
          <div class="meeting-actions">
            <button
              type="button"
              :class="{ active: isAudioAllowed }"
              @click="changeMicroStatus"
            >
              <i
                class="icon"
                :class="`${
                  isAudioAllowed ? 'icon-micro-on' : 'icon-micro-off'
                }`"
              />
            </button>
            <button
              type="button"
              :class="{ active: isCameraAllowed }"
              @click="changeCameraStatus"
            >
              <i
                class="icon"
                :class="`${
                  isCameraAllowed ? 'icon-camera-on' : 'icon-camera-off'
                }`"
              />
            </button>
          </div>
          <p v-if="!isEnumerateCameraDevices">Камера не найдена</p>
          <template v-else>
            <video
              v-if="isCameraAllowed"
              playsinline="true"
              ref="cameraVideo"
              autoplay=""
            />
            <p v-else>Камера выключена</p>
          </template>
        </div>

        <div
          class="meeting-devices"
          v-if="isEnumerateAudioDevices || isEnumerateCameraDevices"
        >
          <div class="meeting-devices__wrapper">
            <AppSelector
              v-if="isEnumerateAudioDevices && audioDevices.length"
              label="Микрофон"
              size="medium"
              :selected-option="selectedMicro"
              :options="audioDevices"
              option-name="label"
              option-value="deviceId"
              @select="selectMicro"
            />
            <AppSelector
              v-if="isEnumerateCameraDevices && cameraDevices.length"
              label="Камера"
              size="medium"
              :selected-option="selectedCamera"
              :options="cameraDevices"
              option-name="label"
              option-value="deviceId"
              @select="selectCamera"
            />
          </div>

          <AppSelector
            label="Устройство вывода"
            size="medium"
            option-name="label"
            option-value="deviceId"
            :selected-option="selectedOutputDevice"
            :options="audioOutputDevices"
            @select="selectOutputDevice"
            v-if="isEnumerateAudioDevices && audioOutputDevices.length"
            class="output-select"
          />
        </div>

        <button
          @click="joinRoom"
          class="button button_large"
          v-if="width < 1024"
        >
          Присоединиться к встрече
        </button>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
.meeting-settings {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  height: 100dvh;
  background: $background-ultralight-gray;

  @media (max-width: 1023px) {
    padding: calc($base-space * 6) 0;
  }

  @media (max-width: 767px) {
    background: $background-light;
    align-items: flex-start;
    padding: 0;
  }

  &__wrapper {
    width: 1096px;
    border-radius: 24px;
    background: $background-light;
    box-shadow: 0 4px 32px 0 rgba(7, 6, 52, 0.03);
    margin: auto;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: start;

    @media (max-width: 1239px) {
      width: 928px;
    }

    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      gap: 0;
    }

    @media (max-width: 767px) {
      box-shadow: none;
      padding: 16px;
      width: 100vw;
    }

    > span {
      width: 1px;
      height: calc(100% - 96px);
      background-color: $stroke-fourth;
      margin: calc($base-space * 12) 0 0;

      @media (max-width: 1023px) {
        height: 1px;
        width: 100%;
        margin: calc($base-space * 4) 0 0;
      }
    }
  }

  &__info {
    padding: calc($base-space * 12);
    position: relative;

    @media (max-width: 1023px) {
      padding: calc($base-space * 8) calc($base-space * 8) 0;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    > p {
      @include s-text-regular();
      color: $text-dark-third;
      margin: calc($base-space * 6) 0 0;

      @media (max-width: 1023px) {
        margin: calc($base-space * 5) 0 0;
      }
    }

    > button {
      margin: calc($base-space * 8) 0 0;
      width: 100%;

      @media (max-width: 767px) {
        margin: calc($base-space * 6) 0 0;
      }
    }
  }

  &__room {
    display: grid;
    grid-template-columns: 1fr;
    gap: calc($base-space * 2);
    margin: calc($base-space * 4) 0 0;

    @media (max-width: 1023px) {
      margin: calc($base-space * 3) 0 0;
    }

    > button {
      width: 100%;
      padding: calc($base-space * 4);
      display: grid;
      grid-template-columns: 24px 1fr auto;
      gap: calc($base-space * 3);
      @include s-text-regular();
      color: $text-dark-primary;
      border-radius: 16px;
      background: $background-ultralight-gray;
      text-align: left;
      transition: ease-out 0.05s;

      > i {
        width: 24px;
        height: 24px;
        background-color: $text-dark-third;
      }

      > span {
        width: 40px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid $stroke-second;
        position: relative;
        transition: ease-out 0.05s;

        &:after {
          content: "";
          width: 16px;
          height: 16px;
          background-color: $stroke-second;
          position: absolute;
          border-radius: 50%;
          left: 4px;
          top: 3px;
          transition: ease-out 0.05s;
        }
      }

      &:hover {
        background-color: $background-light-gray;

        > span {
          border-color: $stroke-primary;

          &:after {
            background-color: $stroke-primary;
          }
        }
      }

      &.active {
        background-color: $background-ultralight-accent;

        > span {
          border-color: $background-main-accent;
          background-color: $background-main-accent;

          &:after {
            background-color: $background-light;
            right: 4px;
            left: auto;
          }
        }

        &:hover {
          background-color: $background-light-accent;
        }
      }
    }
  }

  &__devices {
    padding: calc($base-space * 12);

    @media (max-width: 1023px) {
      padding: calc($base-space * 4) calc($base-space * 8) calc($base-space * 8);
      width: 100%;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    > button {
      margin: calc($base-space * 6) 0 0;
      width: 100%;
    }
  }
}

.meeting-name {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  gap: calc($base-space * 8);

  > img {
    @media (max-width: 767px) {
      margin: 0 auto;
    }
  }

  > h4 {
    @include max-stroke(1);
    @include h4();
    color: $text-dark-primary;
  }
}

.meeting-camera {
  aspect-ratio: 16/9;
  border-radius: 12px;
  background: $background-main-overlay;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
  overflow: hidden;

  @media (max-width: 767px) {
    margin: calc($base-space * 4) 0 0;
  }

  > p {
    text-align: center;
    @include l-text-medium();
    color: $text-light-primary;
  }

  > video {
    max-width: 100%;
    max-height: 100%;
  }
}

.meeting-devices {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: calc($base-space * 3);
  margin: calc($base-space * 4) 0 0;

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: calc($base-space * 3);
  }

  > .output-select {
    flex-shrink: 0;
  }

  @media (max-width: 767px) {
    flex-flow: column;
  }
}

.meeting-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: calc($base-space * 2);
  position: absolute;
  top: 8px;
  right: 8px;

  > button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: $background-ultralight-gray;
    z-index: 2;
    transition: ease-out 0.05s;

    &:hover {
      background-color: $background-light-gray;
    }

    > i {
      width: 20px;
      height: 20px;
      background-color: $text-error;
    }

    &.active {
      background-color: #cff6e1;

      > i {
        background-color: rgba(0, 137, 63, 1);
      }

      &:hover {
        background-color: #b3e3c9;

        > i {
          background-color: rgba(0, 98, 45, 1);
        }
      }
    }
  }
}
</style>
