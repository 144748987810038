<script setup>
import AppModal from "@/components/Ui/AppModal.vue";
import { defineEmits, defineProps, ref } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["close"]);

const props = defineProps({
  webinar: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const buttonLoader = ref(false);
const deleteOnline = () => {
  if (!buttonLoader.value) {
    buttonLoader.value = true;

    let data = structuredClone(props.webinar.courseUnit.data);
    data.status = "ended";

    store
      .dispatch("lesson/editLessonInformation", {
        course_id: props.webinar.course_id,
        id: props.webinar.course_unit_id,
        data: {
          data: data,
        },
      })
      .then(() => {
        store
          .dispatch("webinar/endWebinar", props.webinar.slug)
          .then(() => {
            emit("close");
            store.commit("lesson/CLEAR_LESSON");
          })
          .catch((error) => {
            store.commit("system/SET_NOTIFICATION", {
              type: "error",
              name: "Что-то пошло не так",
              description: error.response.data.message,
            });
          })
          .finally(() => {
            buttonLoader.value = false;
          });
      });
  }
};
</script>

<template>
  <AppModal @close="emit('close')" size="medium">
    <div class="default-modal">
      <h3>Завершение вебинара</h3>

      <p>Вы действительно хотите завершить вебинар?</p>

      <div class="default-modal__actions">
        <button
          class="button button_large"
          :disabled="buttonLoader"
          @click="deleteOnline"
        >
          Завершить
        </button>

        <button
          class="button button_large button_secondary"
          @click="emit('close')"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppModal>
</template>

<style scoped lang="scss"></style>
