<script setup>
import AppDrawer from "@/components/Ui/AppDrawer.vue";
import { computed, defineEmits } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["close"]);

const store = useStore();

const moderatorSettings = computed(
  () => store.state.meeting.roomInfo.moderator
);

const updateSettings = (action) => {
  store.dispatch("meeting/updateRoomSettings", action);
};
</script>

<template>
  <AppDrawer @close="emit('close')">
    <div class="mobile-actions">
      <h3>Выполнить для всех участников</h3>
      <div class="mobile-actions__list">
        <button type="button" @click="updateSettings('audio_cant_unmute')">
          <i
            class="icon"
            :class="`${
              moderatorSettings.audio_cant_unmute
                ? ' icon-micro-on'
                : ' icon-micro-off'
            }`"
          />
          {{
            moderatorSettings.audio_cant_unmute
              ? "Разблокировать микрофон"
              : "Заблокировать микрофон"
          }}
        </button>
        <span></span>
        <button type="button" @click="updateSettings('video_cant_unhide')">
          <i
            class="icon"
            :class="`${
              moderatorSettings.video_cant_unhide
                ? ' icon-camera-on'
                : ' icon-camera-off'
            }`"
          />
          {{
            moderatorSettings.video_cant_unhide
              ? "Разблокировать камеру"
              : "Заблокировать камеру"
          }}
        </button>
        <span></span>
        <button type="button" @click="updateSettings('screen_cant_share')">
          <i
            class="icon"
            :class="`${
              moderatorSettings.screen_cant_share
                ? ' icon-screenshare-on'
                : ' icon-screenshare-off'
            }`"
          />
          {{
            moderatorSettings.screen_cant_share
              ? "Разблокировать демонстрацию экрана"
              : "Заблокировать демонстрацию экрана"
          }}
        </button>
      </div>

      <button
        type="button"
        class="button button_secondary button_large"
        @click="emit('close')"
      >
        Отмена
      </button>
    </div>
  </AppDrawer>
</template>

<style scoped lang="scss">
.mobile-actions {
  > h3 {
    color: $text-dark-primary;
    @include h4();
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    padding: calc($base-space * 2) 0;
    margin: calc($base-space * 6) 0 0;
    border-top: 1px solid $stroke-fourth;
    border-bottom: 1px solid $stroke-fourth;

    > button {
      padding: calc($base-space * 2.5) 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: calc($base-space * 2);
      @include m-text-regular();
      color: $text-dark-primary;

      > i {
        width: 20px;
        height: 20px;
        background-color: $text-dark-third;
      }
    }

    > span {
      width: 100%;
      height: 1px;
      background-color: $stroke-fourth;
      margin: calc($base-space * 2) 0;
    }
  }

  > button {
    margin: calc($base-space * 6) 0 0;
    width: 100%;
  }
}
</style>
