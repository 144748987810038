<script setup>
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const meeting = computed(() => store.state.meeting.meeting);
</script>

<template>
  <section class="meeting-end">
    <div class="meeting-end__wrapper">
      <span>
        <i class="icon icon-block" />
      </span>
      <h3>Встреча закончилась</h3>

      <router-link
        :to="{ name: 'CourseView', params: { id: meeting.course_id } }"
        class="button button_large"
      >
        Перейти к курсу
      </router-link>
    </div>
  </section>
</template>

<style scoped lang="scss">
.meeting-end {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background-color: $background-ultralight-gray;

  &__wrapper {
    width: 480px;
    padding: calc($base-space * 12);
    border-radius: 24px;
    background-color: $background-light;
    box-shadow: 0 4px 32px 0 rgba(7, 6, 52, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    > span {
      width: 72px;
      height: 72px;
      background-color: $background-error-message;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      > i {
        width: 45px;
        height: 46px;
        background-color: $text-error;
      }
    }

    > h3 {
      @include h4();
      color: $text-dark-primary;
      text-align: center;
      margin: calc($base-space * 8) 0 0;
    }

    > p {
      margin: calc($base-space * 2) 0 0;
      color: $text-dark-second;
      @include m-text-regular();
      text-align: center;
    }

    > a {
      margin: calc($base-space * 8) 0 0;
      width: 100%;
    }
  }
}
</style>
