<script setup>
import AppModal from "@/components/Ui/AppModal.vue";
import { computed, defineEmits, ref } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["close"]);
const showTooltip = ref(false);
const store = useStore();

const meeting = computed(() => store.state.meeting.meeting);

const shareLink = computed(() => {
  if (window.location.host === "partner.coorser.com") {
    return `https://coorser.com/meet/${meeting.value.slug}`;
  }

  if (window.location.host === "partner.coorser.ru") {
    return `https://coorser.ru/meet/${meeting.value.slug}`;
  }

  return `https://kursir-frontend-staging.stage.rccore.net/meet/${meeting.value.slug}`;
});

const copyMeetingLink = () => {
  navigator.clipboard.writeText(shareLink.value).then(() => {
    showTooltip.value = true;

    setTimeout(() => {
      showTooltip.value = false;
    }, 2000);
  });
};
</script>

<template>
  <AppModal @close="emit('close')">
    <div class="default-modal share-modal">
      <h3>Поделиться ссылкой</h3>
      <p>
        Отправьте ссылку участникам, чтобы они смогли присоединиться к встрече
      </p>

      <div class="share-modal__actions">
        <input type="text" readonly :value="shareLink" />

        <Popper
          content="Ссылка скопирована"
          :show="showTooltip"
          arrow
          offset-distance="8"
        >
          <button
            type="button"
            class="button button_medium"
            @click="copyMeetingLink"
          >
            <i class="icon icon-copy" />
            Копировать
          </button>
        </Popper>
      </div>
    </div>
  </AppModal>
</template>

<style scoped lang="scss">
.share-modal {
  &__actions {
    margin: calc($base-space * 8) 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: calc($base-space * 2);

    > input {
      padding: 0 calc($base-space * 4);
      height: 48px;
      border-radius: 8px;
      background-color: $background-ultralight-gray;
      border: none;
      outline: none;
      width: 100%;
      @include m-text-regular();
      color: $text-dark-primary;

      @media (max-width: 1023px) {
        height: 40px;
      }
    }

    &:deep(.popper) {
      padding: ($base-space * 3) ($base-space * 4);
      @include s-text-regular();
      background-color: $background-light;
      color: $text-dark-primary;
      border-radius: 8px;
      @include main-shadow();
    }

    :deep(.popper #arrow::before) {
      background: $background-light;
    }
  }
}
</style>
