<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import moment from "moment/moment";
import MeetingShareLink from "@/components/Meeting/MeetingShareLink.vue";
import MeetingSettingsModal from "@/components/Meeting/MeetingSettingsModal.vue";
import MeetingEndModal from "@/components/Meeting/MeetingEndModal.vue";
import MeetingEnd from "@/components/Meeting/MeetingEnd.vue";
import MeetingSettings from "@/components/Meeting/MeetingSettings.vue";
import MeetingEnded from "@/components/Meeting/MeetingEnded.vue";
import MobileMeetingSettings from "@/components/Meeting/Mobile/MobileMeetingSettings.vue";
import MeetingLoading from "@/components/Meeting/MeetingLoading.vue";

const showShareModal = ref(false);
const showSettingsModal = ref(false);
const showEndModal = ref(false);
const route = useRoute();
const router = useRouter();
const store = useStore();
const user = computed(() => store.state.user.user);
const meeting = computed(() => store.state.meeting.meeting);
const meetingState = computed(() => store.state.meeting.state);
const meetingStartTime = computed(() => store.state.meeting.meeting.started_at);
const showMobileActions = ref(false);

const onlineTime = ref(null);
const timer = ref({
  seconds: 0,
  minutes: 0,
  hours: 0,
});

onBeforeMount(() => {
  store.dispatch("user/loadProfileInformation").then(() => {
    if (!user.value.first_name || !user.value.last_name) {
      router.push({
        name: "AuthView",
      });
    }

    store
      .dispatch("meeting/loadMeetingInfo", route.params.id)
      .then(() => {
        if (meeting.value.status !== "ended") {
          store.dispatch("meeting/recreateMeetingRoom", route.params.id);
          if (!meeting.value.started_at) {
            store.dispatch("meeting/startMeeting", route.params.id);
          }

          if (meeting.value.status !== "ended") {
            store.dispatch("meeting/initDevices");
          }

          onlineTime.value = setInterval(() => {
            let diffTime = moment(moment().utcOffset(6, true)).diff(
              meetingStartTime.value
            );
            let duration = moment.duration(diffTime);

            timer.value.hours = duration.hours();
            timer.value.minutes = duration.minutes();
            timer.value.seconds = duration.seconds();
          }, 1000);
        }
      })
      .catch(() => {
        router.push({ name: "OnlineView" });
      });
  });
});

onBeforeUnmount(() => {
  if (document.getElementById("usedesk-messenger")) {
    document.getElementById("usedesk-messenger").style.display = "block";
  }

  clearInterval(onlineTime.value);

  store.dispatch("meeting/clearMeeting");
});
</script>

<template>
  <MobileMeetingSettings
    @close="showMobileActions = false"
    v-if="showMobileActions"
  />

  <MeetingShareLink v-if="showShareModal" @close="showShareModal = false" />

  <MeetingSettingsModal
    v-if="showSettingsModal"
    @close="showSettingsModal = false"
  />

  <MeetingEndModal
    :meeting="meeting"
    v-if="showEndModal"
    @close="showEndModal = false"
  />

  <template v-if="meeting">
    <MeetingEnd v-if="meetingState === 'end'" />

    <template v-else-if="meeting.status !== 'ended'">
      <MeetingSettings v-if="meetingState === 'settings'" />
      <MeetingLoading v-if="meetingState === 'join'" />

      <main class="meeting" v-if="meetingState === 'room'">
        <header class="meeting-header">
          <div class="meeting-header__info">
            <img src="@/assets/images/logo-meet.svg" alt="" />
            <span></span>
            <h3 v-if="meeting.courseUnit">
              <i class="icon icon-live_fill" />
              <span>{{ meeting.courseUnit.name }}</span>
            </h3>
          </div>

          <div class="meeting-header__actions">
            <button type="button" @click="showShareModal = true">
              <i class="icon icon-share" /> Поделиться ссылкой
            </button>
            <button type="button" @click="showSettingsModal = true">
              <i class="icon icon-settings" /> Настройки
            </button>
            <button type="button" @click="showEndModal = true">
              Завершить встречу <i class="icon icon-exit" />
            </button>
          </div>

          <p
            v-if="
              meetingStartTime &&
              (timer.hours || timer.minutes || timer.seconds) &&
              false
            "
          >
            <i class="icon icon-clock-fill" />
            <template v-if="timer.hours">
              {{
                timer.hours < 10 ? `0${timer.hours}` : timer.hours
              }}:</template
            >{{ timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes }}:{{
              timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds
            }}
          </p>

          <button type="button" @click="showMobileActions = !showMobileActions">
            <i class="icon icon-more_vert_fill" />
          </button>
        </header>

        <RouterView />
      </main>
    </template>

    <MeetingEnded v-else />
  </template>

  <div class="meeting-loading" v-else>
    <img src="@/assets/images/loader.svg" alt="" />
  </div>
</template>

<style scoped lang="scss">
.meeting {
  background: $background-ultralight-accent;
  height: 100dvh;
  max-height: 100dvh;
  overflow: hidden;
}

.meeting-loading {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 120px;
  }
}

.meeting-header {
  background-color: $background-light;
  height: 72px;
  border-bottom: 1px solid $stroke-fourth;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 calc($base-space * 4);

  @media (max-width: 1023px) {
    height: 52px;
    padding: 0 calc($base-space * 4);
    gap: calc($base-space * 2);
  }

  &__info {
    display: flex;
    align-items: center;
    justify-items: flex-start;

    > img {
      width: 102px;

      @media (max-width: 1023px) {
        display: none;
      }
    }

    > span {
      width: 1px;
      height: 24px;
      background-color: $stroke-third;
      margin: 0 calc($base-space * 4);

      @media (max-width: 1023px) {
        display: none;
      }
    }

    > h3 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include s-text-medium();
      color: $text-dark-primary;
      gap: calc($base-space * 2);

      > i {
        width: 20px;
        height: 20px;
        background-color: $text-dark-third;
      }

      > span {
        @include max-stroke(1);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;

    @media (max-width: 1023px) {
      display: none;
    }

    > button {
      padding: calc($base-space * 2) calc($base-space * 3);
      @include s-text-regular();
      color: $text-dark-second;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: calc($base-space * 2);

      > i {
        width: 20px;
        height: 20px;
        background-color: $text-dark-second;
      }

      &:last-child {
        color: $text-error;

        > i {
          background-color: $text-error;
        }
      }
    }
  }

  > p {
    @include m-text-medium();
    color: $text-dark-third;
    display: none;

    @media (max-width: 1023px) {
      display: block;
    }
  }

  > button {
    width: 32px;
    height: 32px;

    align-items: center;
    justify-content: center;
    display: none;

    @media (max-width: 1023px) {
      display: flex;
    }

    > i {
      width: 24px;
      height: 24px;
      background-color: $text-dark-third;
    }
  }
}
</style>
