<script setup>
import AppSelector from "@/components/Ui/AppSelector.vue";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const isEnumerateCameraDevices = computed(
  () => store.state.meeting.isEnumerateCameraDevices
);
const isEnumerateAudioDevices = computed(
  () => store.state.meeting.isEnumerateAudioDevices
);

const cameraDevices = computed(() => store.state.meeting.cameraDevices);
const audioDevices = computed(() => store.state.meeting.audioDevices);

const selectedCameraDevice = computed(
  () => store.state.meeting.selectedCameraDevice
);
const selectedAudioDevice = computed(
  () => store.state.meeting.selectedAudioDevice
);

const selectedCamera = computed(() =>
  cameraDevices.value.find(
    (device) => device.deviceId === selectedCameraDevice.value
  )
);

const selectedMicro = computed(() =>
  audioDevices.value.find(
    (device) => device.deviceId === selectedAudioDevice.value
  )
);

const audioOutputDevices = computed(
  () => store.state.meeting.audioOutputDevices
);

const selectedAudioOutputDevice = computed(
  () => store.state.meeting.selectedAudioOutputDevice
);

const selectedOutputDevice = computed(() =>
  audioOutputDevices.value.find(
    (device) => device.deviceId === selectedAudioOutputDevice.value
  )
);

const selectMicro = (item) => {
  store.dispatch("meeting/changeStreamAudio", item.deviceId);
};

const selectCamera = (item) => {
  store.commit("meeting/SET_STATE", {
    state: "selectedCameraDevice",
    value: item.deviceId,
  });
  store.dispatch("meeting/changeStreamCamera", item.deviceId);
};

const selectOutputDevice = (item) => {
  store.commit("meeting/SET_STATE", {
    state: "selectedAudioOutputDevice",
    value: item.deviceId,
  });
};
</script>

<template>
  <div class="settings-devices">
    <h4>Мои устройства</h4>
    <p>Выберите устройства из списка</p>
    <AppSelector
      v-if="isEnumerateAudioDevices && audioDevices.length"
      label="Микрофон"
      size="medium"
      :selected-option="selectedMicro"
      :options="audioDevices"
      option-name="label"
      option-value="deviceId"
      @select="selectMicro"
    />
    <AppSelector
      v-if="isEnumerateCameraDevices && cameraDevices.length"
      label="Камера"
      size="medium"
      :selected-option="selectedCamera"
      :options="cameraDevices"
      option-name="label"
      option-value="deviceId"
      @select="selectCamera"
    />

    <AppSelector
      label="Устройство вывода"
      size="medium"
      option-name="label"
      option-value="deviceId"
      :selected-option="selectedOutputDevice"
      :options="audioOutputDevices"
      @select="selectOutputDevice"
      v-if="isEnumerateAudioDevices && audioOutputDevices.length"
      class="output-select"
    />
  </div>
</template>

<style scoped lang="scss">
.settings-devices {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  > h4 {
    @include h4();
    color: $text-dark-primary;
  }

  > p {
    margin: calc($base-space * 2) 0 0;
    @include s-text-regular();
    color: $text-dark-third;
  }

  > .app-selector {
    &:first-of-type {
      margin: calc($base-space * 6) 0 0;
    }
    &:not(:first-of-type) {
      margin: calc($base-space * 4) 0 0;
    }
  }
}
</style>
